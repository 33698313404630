/* app/Status/CurrentWind.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	text
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-qz4ml5-style";
	style.textContent = ".wind.svelte-qz4ml5{position:absolute;top:0;left:100vw;padding:32px;transform:translateX(-100%);font-size:24pt;color:white;font-family:var(--font-chinese)}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let t_value = /*CHARACTER*/ ctx[1][/*wind*/ ctx[0]] + "";
	let t;

	return {
		c() {
			div = element("div");
			t = text(t_value);
			attr(div, "class", "wind svelte-qz4ml5");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, [dirty]) {
			if (dirty & /*wind*/ 1 && t_value !== (t_value = /*CHARACTER*/ ctx[1][/*wind*/ ctx[0]] + "")) set_data(t, t_value);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { wind } = $$props;
	const CHARACTER = { Pei: "北", Nan: "南", Shaa: "西", Ton: "东" };

	$$self.$set = $$props => {
		if ("wind" in $$props) $$invalidate(0, wind = $$props.wind);
	};

	return [wind, CHARACTER];
}

class CurrentWind extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-qz4ml5-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { wind: 0 });
	}
}

export default CurrentWind;