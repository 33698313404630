/* app/Status/TextTile.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import images from "../../tiles/Regular/*.svg";
import context from "../../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-ttpabz-style";
	style.textContent = ".tile.svelte-ttpabz{display:inline-block;width:23px;height:44px;border-radius:3px;background-position:center;background-size:20px 40px;background-repeat:no-repeat;background-color:white}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let span;

	return {
		c() {
			span = element("span");
			attr(span, "class", "tile svelte-ttpabz");
			attr(span, "style", /*style*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, span, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*style*/ 1) {
				attr(span, "style", /*style*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(span);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $store;
	const { store } = context();
	component_subscribe($$self, store, value => $$invalidate(3, $store = value));
	let { tile } = $$props;
	let style;
	

	$$self.$set = $$props => {
		if ("tile" in $$props) $$invalidate(2, tile = $$props.tile);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$store, tile*/ 12) {
			$: {
				const info = $store.tiles[tile];

				if (typeof info.value === "number") {
					$$invalidate(0, style = `background-image: url(${images[info.suit + info.value]})`);
				} else {
					$$invalidate(0, style = `background-image: url(${images[info.value]})`);
				}
			}
		}
	};

	return [style, store, tile];
}

class TextTile extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-ttpabz-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { tile: 2 });
	}
}

export default TextTile;