/* app/App.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init as init_1,
	insert,
	listen,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import Table from "./Table.svelte";
import Title from "./Title.svelte";
import Tiles from "./Tiles.svelte";
import Status from "./Status/Status.svelte";
import handler from "../game/handler.js";
import context, { init } from "../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-2s57ao-style";
	style.textContent = ".layer.svelte-2s57ao{position:absolute;top:0;left:0}.full.svelte-2s57ao{width:100%;height:100%}.title.svelte-2s57ao,.input.svelte-2s57ao,.button.svelte-2s57ao{color:white}.form.svelte-2s57ao{display:flex;flex-direction:column;width:100%;max-width:600px;margin:50px auto}.input.svelte-2s57ao{font-size:16pt;border:none;background:none;border-bottom:1px solid rgba(255, 255, 255, 0.25);font-family:var(--font-english);width:100%}.button.svelte-2s57ao{background:none;border:none;cursor:pointer;margin:8px 0;padding:8px 16px;margin-left:auto;font-family:var(--font-english)}.button.svelte-2s57ao:disabled{opacity:0.5 }.error.svelte-2s57ao,.info.svelte-2s57ao{padding:16px 0;font-size:14pt;font-family:var(--font-english)}";
	append(document.head, style);
}

// (78:2) <Table     angle={state === PLAY ? tableAngle : 0}     rotation={$store ? ['Ton', 'Nan', 'Shaa', 'Pei'].indexOf($store.playerWind(name)) * 90 : 0}     bottomLabel={$store && $store.Ton && $store.Ton.name}     topLabel={$store && $store.Shaa && $store.Shaa.name}     rightLabel={$store && $store.Nan && $store.Nan.name}     leftLabel={$store && $store.Pei && $store.Pei.name}     highlightSide={SIDE[$store && $store.turn] || null}     >
function create_default_slot_1(ctx) {
	let current;

	const tiles = new Tiles({
			props: { tableAngle: /*tableAngle*/ ctx[4] }
		});

	return {
		c() {
			create_component(tiles.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tiles, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const tiles_changes = {};
			if (dirty & /*tableAngle*/ 16) tiles_changes.tableAngle = /*tableAngle*/ ctx[4];
			tiles.$set(tiles_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tiles.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tiles.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tiles, detaching);
		}
	};
}

// (95:0) {:else}
function create_else_block(ctx) {
	let div;
	let current;

	const title = new Title({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			div = element("div");
			create_component(title.$$.fragment);
			attr(div, "class", "layer full title svelte-2s57ao");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(title, div, null);
			current = true;
		},
		p(ctx, dirty) {
			const title_changes = {};

			if (dirty & /*$$scope, errorMessage, room, state, name*/ 524303) {
				title_changes.$$scope = { dirty, ctx };
			}

			title.$set(title_changes);
		},
		i(local) {
			if (current) return;
			transition_in(title.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(title.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(title);
		}
	};
}

// (91:0) {#if state === PLAY}
function create_if_block(ctx) {
	let div;
	let current;
	const status = new Status({});

	return {
		c() {
			div = element("div");
			create_component(status.$$.fragment);
			attr(div, "class", "layer svelte-2s57ao");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(status, div, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(status.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(status.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(status);
		}
	};
}

// (99:8) {#if state === identification}
function create_if_block_3(ctx) {
	let input;
	let t0;
	let button;
	let t1;
	let button_disabled_value;
	let dispose;

	return {
		c() {
			input = element("input");
			t0 = space();
			button = element("button");
			t1 = text("Confirm");
			attr(input, "class", "input svelte-2s57ao");
			attr(input, "placeholder", "Enter your name");
			input.autofocus = true;
			attr(input, "tabindex", "1");
			attr(button, "class", "button svelte-2s57ao");
			button.disabled = button_disabled_value = !/*name*/ ctx[0];
		},
		m(target, anchor, remount) {
			insert(target, input, anchor);
			set_input_value(input, /*name*/ ctx[0]);
			insert(target, t0, anchor);
			insert(target, button, anchor);
			append(button, t1);
			input.focus();
			if (remount) run_all(dispose);

			dispose = [
				listen(input, "input", /*input_input_handler*/ ctx[15]),
				listen(input, "keydown", /*submit*/ ctx[10]),
				listen(button, "click", /*click_handler*/ ctx[16])
			];
		},
		p(ctx, dirty) {
			if (dirty & /*name*/ 1 && input.value !== /*name*/ ctx[0]) {
				set_input_value(input, /*name*/ ctx[0]);
			}

			if (dirty & /*name*/ 1 && button_disabled_value !== (button_disabled_value = !/*name*/ ctx[0])) {
				button.disabled = button_disabled_value;
			}
		},
		d(detaching) {
			if (detaching) detach(input);
			if (detaching) detach(t0);
			if (detaching) detach(button);
			run_all(dispose);
		}
	};
}

// (103:8) {#if state === location}
function create_if_block_2(ctx) {
	let div;
	let t0;
	let b;
	let t1;
	let t2;
	let t3;
	let input;
	let t4;
	let button;
	let t5;
	let button_disabled_value;
	let dispose;

	return {
		c() {
			div = element("div");
			t0 = text("Welcome, ");
			b = element("b");
			t1 = text(/*name*/ ctx[0]);
			t2 = text(".");
			t3 = space();
			input = element("input");
			t4 = space();
			button = element("button");
			t5 = text("Confirm");
			attr(div, "class", "info svelte-2s57ao");
			attr(input, "class", "input svelte-2s57ao");
			attr(input, "placeholder", "Enter a game name");
			input.autofocus = true;
			attr(input, "tabindex", "1");
			attr(button, "class", "button svelte-2s57ao");
			button.disabled = button_disabled_value = !/*room*/ ctx[1];
		},
		m(target, anchor, remount) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, b);
			append(b, t1);
			append(div, t2);
			insert(target, t3, anchor);
			insert(target, input, anchor);
			set_input_value(input, /*room*/ ctx[1]);
			insert(target, t4, anchor);
			insert(target, button, anchor);
			append(button, t5);
			input.focus();
			if (remount) run_all(dispose);

			dispose = [
				listen(input, "input", /*input_input_handler_1*/ ctx[17]),
				listen(input, "keydown", /*submit*/ ctx[10]),
				listen(button, "click", /*click_handler_1*/ ctx[18])
			];
		},
		p(ctx, dirty) {
			if (dirty & /*name*/ 1) set_data(t1, /*name*/ ctx[0]);

			if (dirty & /*room*/ 2 && input.value !== /*room*/ ctx[1]) {
				set_input_value(input, /*room*/ ctx[1]);
			}

			if (dirty & /*room*/ 2 && button_disabled_value !== (button_disabled_value = !/*room*/ ctx[1])) {
				button.disabled = button_disabled_value;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (detaching) detach(t3);
			if (detaching) detach(input);
			if (detaching) detach(t4);
			if (detaching) detach(button);
			run_all(dispose);
		}
	};
}

// (108:8) {#if errorMessage}
function create_if_block_1(ctx) {
	let div;
	let t;

	return {
		c() {
			div = element("div");
			t = text(/*errorMessage*/ ctx[2]);
			attr(div, "class", "error svelte-2s57ao");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*errorMessage*/ 4) set_data(t, /*errorMessage*/ ctx[2]);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (97:4) <Title>
function create_default_slot(ctx) {
	let div;
	let t0;
	let t1;
	let if_block0 = /*state*/ ctx[3] === /*identification*/ ctx[9] && create_if_block_3(ctx);
	let if_block1 = /*state*/ ctx[3] === /*location*/ ctx[8] && create_if_block_2(ctx);
	let if_block2 = /*errorMessage*/ ctx[2] && create_if_block_1(ctx);

	return {
		c() {
			div = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			attr(div, "class", "form svelte-2s57ao");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block0) if_block0.m(div, null);
			append(div, t0);
			if (if_block1) if_block1.m(div, null);
			append(div, t1);
			if (if_block2) if_block2.m(div, null);
		},
		p(ctx, dirty) {
			if (/*state*/ ctx[3] === /*identification*/ ctx[9]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(div, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*state*/ ctx[3] === /*location*/ ctx[8]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(div, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*errorMessage*/ ctx[2]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					if_block2.m(div, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let t;
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	let dispose;

	const table = new Table({
			props: {
				angle: /*state*/ ctx[3] === /*PLAY*/ ctx[7]
				? /*tableAngle*/ ctx[4]
				: 0,
				rotation: /*$store*/ ctx[5]
				? ["Ton", "Nan", "Shaa", "Pei"].indexOf(/*$store*/ ctx[5].playerWind(/*name*/ ctx[0])) * 90
				: 0,
				bottomLabel: /*$store*/ ctx[5] && /*$store*/ ctx[5].Ton && /*$store*/ ctx[5].Ton.name,
				topLabel: /*$store*/ ctx[5] && /*$store*/ ctx[5].Shaa && /*$store*/ ctx[5].Shaa.name,
				rightLabel: /*$store*/ ctx[5] && /*$store*/ ctx[5].Nan && /*$store*/ ctx[5].Nan.name,
				leftLabel: /*$store*/ ctx[5] && /*$store*/ ctx[5].Pei && /*$store*/ ctx[5].Pei.name,
				highlightSide: SIDE[/*$store*/ ctx[5] && /*$store*/ ctx[5].turn] || null,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*state*/ ctx[3] === /*PLAY*/ ctx[7]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div = element("div");
			create_component(table.$$.fragment);
			t = space();
			if_block.c();
			if_block_anchor = empty();
			attr(div, "class", "layer full svelte-2s57ao");
		},
		m(target, anchor, remount) {
			insert(target, div, anchor);
			mount_component(table, div, null);
			insert(target, t, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
			if (remount) dispose();
			dispose = listen(window, "wheel", /*scroll*/ ctx[11]);
		},
		p(ctx, [dirty]) {
			const table_changes = {};

			if (dirty & /*state, tableAngle*/ 24) table_changes.angle = /*state*/ ctx[3] === /*PLAY*/ ctx[7]
			? /*tableAngle*/ ctx[4]
			: 0;

			if (dirty & /*$store, name*/ 33) table_changes.rotation = /*$store*/ ctx[5]
			? ["Ton", "Nan", "Shaa", "Pei"].indexOf(/*$store*/ ctx[5].playerWind(/*name*/ ctx[0])) * 90
			: 0;

			if (dirty & /*$store*/ 32) table_changes.bottomLabel = /*$store*/ ctx[5] && /*$store*/ ctx[5].Ton && /*$store*/ ctx[5].Ton.name;
			if (dirty & /*$store*/ 32) table_changes.topLabel = /*$store*/ ctx[5] && /*$store*/ ctx[5].Shaa && /*$store*/ ctx[5].Shaa.name;
			if (dirty & /*$store*/ 32) table_changes.rightLabel = /*$store*/ ctx[5] && /*$store*/ ctx[5].Nan && /*$store*/ ctx[5].Nan.name;
			if (dirty & /*$store*/ 32) table_changes.leftLabel = /*$store*/ ctx[5] && /*$store*/ ctx[5].Pei && /*$store*/ ctx[5].Pei.name;
			if (dirty & /*$store*/ 32) table_changes.highlightSide = SIDE[/*$store*/ ctx[5] && /*$store*/ ctx[5].turn] || null;

			if (dirty & /*$$scope, tableAngle*/ 524304) {
				table_changes.$$scope = { dirty, ctx };
			}

			table.$set(table_changes);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(table.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(table.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(table);
			if (detaching) detach(t);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
			dispose();
		}
	};
}

const SIDE = {
	Ton: "bottom",
	Shaa: "top",
	Nan: "right",
	Pei: "left"
};

const SPEED = 3;

function instance($$self, $$props, $$invalidate) {
	let $store;
	let { socket } = $$props;
	init(socket);
	const ctx = context();
	const { store } = ctx;
	component_subscribe($$self, store, value => $$invalidate(5, $store = value));
	let name, room;
	let errorMessage;
	const PLAY = Symbol();

	async function location() {
		if (!room) {
			return;
		}

		$$invalidate(2, errorMessage = undefined);

		try {
			const { schema } = await socket.send("location", { room });
			handler(schema, ctx);
			$$invalidate(3, state = PLAY);
		} catch(error) {
			console.log(error);
			$$invalidate(2, errorMessage = error);
		}
	}

	async function identification() {
		if (!name) {
			return;
		}

		$$invalidate(2, errorMessage = undefined);

		try {
			await socket.send("identification", { name });
			$$invalidate(12, socket.name = name, socket);
			$$invalidate(3, state = location);
		} catch(error) {
			$$invalidate(2, errorMessage = error);
		}
	}

	let state = identification;

	function submit(event) {
		if (event.key == "Enter") {
			state();
		}
	}

	let adjustment = 0;

	function scroll(event) {
		if (state !== PLAY) return;
		const direction = event.deltaY / Math.abs(event.deltaY);

		if (tableAngle + direction * SPEED <= 90 && tableAngle + direction * SPEED >= 0) {
			$$invalidate(13, adjustment += direction * SPEED);
		}
	}

	function input_input_handler() {
		name = this.value;
		$$invalidate(0, name);
	}

	const click_handler = () => state();

	function input_input_handler_1() {
		room = this.value;
		$$invalidate(1, room);
	}

	const click_handler_1 = () => state();

	$$self.$set = $$props => {
		if ("socket" in $$props) $$invalidate(12, socket = $$props.socket);
	};

	let tableAngle;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*adjustment*/ 8192) {
			$: $$invalidate(4, tableAngle = Math.min(90, Math.max(0, 60 + adjustment)));
		}
	};

	return [
		name,
		room,
		errorMessage,
		state,
		tableAngle,
		$store,
		store,
		PLAY,
		location,
		identification,
		submit,
		scroll,
		socket,
		adjustment,
		ctx,
		input_input_handler,
		click_handler,
		input_input_handler_1,
		click_handler_1
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-2s57ao-style")) add_css();
		init_1(this, options, instance, create_fragment, safe_not_equal, { socket: 12 });
	}
}

export default App;