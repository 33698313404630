/* app/Status/DiscardInfo.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import images from "../../tiles/Regular/*.svg";

function add_css() {
	var style = element("style");
	style.id = "svelte-11ay3b3-style";
	style.textContent = ".tile.svelte-11ay3b3{position:absolute;top:32px;left:32px;width:4vh;height:6vh;border-radius:0.5vh;padding:5%;background-position:center;background-size:contain;background-repeat:no-repeat;background-color:white}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "tile svelte-11ay3b3");
			attr(div, "style", /*style*/ ctx[0]);
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, [dirty]) {
			if (dirty & /*style*/ 1) {
				attr(div, "style", /*style*/ ctx[0]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { tile } = $$props;
	let style;
	

	$$self.$set = $$props => {
		if ("tile" in $$props) $$invalidate(1, tile = $$props.tile);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*tile*/ 2) {
			$: {
				if (typeof tile.value === "number") {
					$$invalidate(0, style = `background-image: url(${images[tile.suit + tile.value]})`);
				} else {
					$$invalidate(0, style = `background-image: url(${images[tile.value]})`);
				}
			}
		}
	};

	return [style, tile];
}

class DiscardInfo extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-11ay3b3-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { tile: 1 });
	}
}

export default DiscardInfo;