/* app/Status/ReadyButton.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	text
} from "svelte/internal";

import context from "../../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-rk0uk4-style";
	style.textContent = ".button.svelte-rk0uk4{position:absolute;left:50vw;top:calc(100vh - 100px);width:300px;height:50px;transform:translateX(-50%);border:1px solid rgba(255, 255, 255, 0.25);border-radius:28px;background:rgba(255, 255, 255, 0.12);font-family:var(--font-english);font-size:16pt;color:white;cursor:pointer}";
	append(document.head, style);
}

// (20:2) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("Ready");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (18:2) {#if $store[$store.playerWind(socket.name)].ready}
function create_if_block(ctx) {
	let t;

	return {
		c() {
			t = text("Cancel");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let show_if;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (show_if == null || dirty & /*$store*/ 1) show_if = !!/*$store*/ ctx[0][/*$store*/ ctx[0].playerWind(/*socket*/ ctx[1].name)].ready;
		if (show_if) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			button = element("button");
			if_block.c();
			attr(button, "class", "button svelte-rk0uk4");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			if_block.m(button, null);
			if (remount) dispose();
			dispose = listen(button, "click", /*toggleReady*/ ctx[3]);
		},
		p(ctx, [dirty]) {
			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(button, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(button);
			if_block.d();
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $store;
	const { socket, store } = context();
	component_subscribe($$self, store, value => $$invalidate(0, $store = value));

	async function toggleReady() {
		try {
			await socket.send("ready", {
				ready: !$store[$store.playerWind(socket.name)].ready
			});
		} catch(error) {
			console.error(error);
		}
	}

	return [$store, socket, store, toggleReady];
}

class ReadyButton extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-rk0uk4-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ReadyButton;