/* app/Status/PlayerList.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import context from "../../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-b8v6w7-style";
	style.textContent = ".container.svelte-b8v6w7{position:absolute;left:50vw;top:50vh;display:grid;grid-template-rows:1fr 1fr;grid-template-columns:1fr 1fr;width:min(50vw, 50vh);height:min(50vw, 50vh);transform:translate(-50%, -50%) rotateZ(45deg);transform-origin:center}.player.svelte-b8v6w7{position:relative;border:1px solid rgba(255, 255, 255, 0.25);background:rgba(255, 255, 255, 0.12)}.name.svelte-b8v6w7,.icon.svelte-b8v6w7,.ready.svelte-b8v6w7{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%) rotateZ(-45deg);transform-origin:center}.name.svelte-b8v6w7{font-family:var(--font-english);font-size:16pt;font-weight:bold;color:white}.icon.svelte-b8v6w7{font-family:var(--font-chinese);font-size:min(12vw, 12vh);color:rgba(255, 255, 255, 0.07)}.ready.svelte-b8v6w7{width:min(15vw, 15vh);height:min(15vw, 15vh);border:3px solid white;border-radius:100%}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (19:6) {#if $store[position]}
function create_if_block(ctx) {
	let span;
	let t0_value = /*$store*/ ctx[1][/*position*/ ctx[4]].name + "";
	let t0;
	let t1;
	let if_block_anchor;
	let if_block = /*$store*/ ctx[1][/*position*/ ctx[4]].ready && !/*$store*/ ctx[1].started && create_if_block_1(ctx);

	return {
		c() {
			span = element("span");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(span, "class", "name svelte-b8v6w7");
		},
		m(target, anchor) {
			insert(target, span, anchor);
			append(span, t0);
			insert(target, t1, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$store, order*/ 3 && t0_value !== (t0_value = /*$store*/ ctx[1][/*position*/ ctx[4]].name + "")) set_data(t0, t0_value);

			if (/*$store*/ ctx[1][/*position*/ ctx[4]].ready && !/*$store*/ ctx[1].started) {
				if (!if_block) {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				} else {
					
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (detaching) detach(span);
			if (detaching) detach(t1);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (21:8) {#if $store[position].ready && !$store.started}
function create_if_block_1(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "ready svelte-b8v6w7");
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (17:2) {#each order as position}
function create_each_block(ctx) {
	let div;
	let t0;
	let span;
	let t1_value = /*CHARACTER*/ ctx[3][/*position*/ ctx[4]] + "";
	let t1;
	let t2;
	let if_block = /*$store*/ ctx[1][/*position*/ ctx[4]] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			t0 = space();
			span = element("span");
			t1 = text(t1_value);
			t2 = space();
			attr(span, "class", "icon svelte-b8v6w7");
			attr(div, "class", "player svelte-b8v6w7");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append(div, t0);
			append(div, span);
			append(span, t1);
			append(div, t2);
		},
		p(ctx, dirty) {
			if (/*$store*/ ctx[1][/*position*/ ctx[4]]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, t0);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*order*/ 1 && t1_value !== (t1_value = /*CHARACTER*/ ctx[3][/*position*/ ctx[4]] + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

function create_fragment(ctx) {
	let div;
	let each_value = /*order*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "container svelte-b8v6w7");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*CHARACTER, order, $store*/ 11) {
				each_value = /*order*/ ctx[0];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $store;
	const { store } = context();
	component_subscribe($$self, store, value => $$invalidate(1, $store = value));
	const CHARACTER = { Pei: "北", Nan: "南", Shaa: "西", Ton: "东" };
	let { order } = $$props;

	$$self.$set = $$props => {
		if ("order" in $$props) $$invalidate(0, order = $$props.order);
	};

	return [order, $store, store, CHARACTER];
}

class PlayerList extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-b8v6w7-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, { order: 0 });
	}
}

export default PlayerList;