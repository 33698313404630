/* app/Status/GameEnd.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	handle_promise,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text
} from "svelte/internal";

import context from "../../game/context";
import Schema from "../../lib/schema";

function add_css() {
	var style = element("style");
	style.id = "svelte-1sp7fjp-style";
	style.textContent = ".container.svelte-1sp7fjp{display:flex;flex-direction:column;align-items:center;position:absolute;left:50vw;top:15vh;width:800px;height:70vh;transform:translateX(-50%);background:rgba(255, 255, 255, 0.20);color:white}.title.svelte-1sp7fjp{font-family:var(--font-chinese);text-align:center;margin:0;padding:10px;width:80%;border-bottom:1px solid rgba(255, 255, 255, 0.25)}.warning.svelte-1sp7fjp{margin:20px;font-family:var(--font-english)}.scores.svelte-1sp7fjp{margin:20px;font-size:24pt;font-family:var(--font-chinese)}.title.svelte-1sp7fjp{font-size:24pt}.play-again.svelte-1sp7fjp{margin-top:auto;font-family:var(--font-english);font-size:14pt;background:rgba(255, 255, 255, 0.2);color:white;border:none;border-top:1px solid rgba(255, 255, 255, 0.75);padding:8px;cursor:pointer;width:100%}";
	append(document.head, style);
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[18] = list[i][0];
	child_ctx[19] = list[i][1].romanized;
	child_ctx[20] = list[i][1].description;
	child_ctx[21] = list[i][1].matched;
	return child_ctx;
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i][0];
	child_ctx[15] = list[i][1];
	return child_ctx;
}

// (1:0) <script>   import context from '../../game/context';   import Schema from '../../lib/schema';    const { store, socket }
function create_catch_block(ctx) {
	return { c: noop, m: noop, p: noop, d: noop };
}

// (337:44)            {#if matched}
function create_then_block(ctx) {
	let t;
	let if_block = /*matched*/ ctx[21] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (/*matched*/ ctx[21]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
		}
	};
}

// (338:10) {#if matched}
function create_if_block(ctx) {
	let div;
	let t0_value = /*name*/ ctx[18] + "";
	let t0;
	let t1;
	let t2_value = /*romanized*/ ctx[19] + "";
	let t2;
	let t3;
	let t4_value = /*description*/ ctx[20] + "";
	let t4;
	let t5;
	let t6_value = /*fan*/ ctx[14] + "";
	let t6;
	let t7;

	return {
		c() {
			div = element("div");
			t0 = text(t0_value);
			t1 = text(" (");
			t2 = text(t2_value);
			t3 = text(": ");
			t4 = text(t4_value);
			t5 = text("): ");
			t6 = text(t6_value);
			t7 = text(" 番");
			attr(div, "class", "rule");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, t1);
			append(div, t2);
			append(div, t3);
			append(div, t4);
			append(div, t5);
			append(div, t6);
			append(div, t7);
		},
		p(ctx, dirty) {
			if (dirty & /*awards*/ 2 && t0_value !== (t0_value = /*name*/ ctx[18] + "")) set_data(t0, t0_value);
			if (dirty & /*awards*/ 2 && t2_value !== (t2_value = /*romanized*/ ctx[19] + "")) set_data(t2, t2_value);
			if (dirty & /*awards*/ 2 && t4_value !== (t4_value = /*description*/ ctx[20] + "")) set_data(t4, t4_value);
			if (dirty & /*awards*/ 2 && t6_value !== (t6_value = /*fan*/ ctx[14] + "")) set_data(t6, t6_value);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (1:0) <script>   import context from '../../game/context';   import Schema from '../../lib/schema';    const { store, socket }
function create_pending_block(ctx) {
	return { c: noop, m: noop, p: noop, d: noop };
}

// (336:6) {#each Object.entries(rules) as [name, { romanized, description, matched }
function create_each_block_1(ctx) {
	let await_block_anchor;
	let promise;

	let info = {
		ctx,
		current: null,
		token: null,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block,
		value: 21
	};

	handle_promise(promise = check(/*matched*/ ctx[21]), info);

	return {
		c() {
			await_block_anchor = empty();
			info.block.c();
		},
		m(target, anchor) {
			insert(target, await_block_anchor, anchor);
			info.block.m(target, info.anchor = anchor);
			info.mount = () => await_block_anchor.parentNode;
			info.anchor = await_block_anchor;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			info.ctx = ctx;

			if (dirty & /*awards*/ 2 && promise !== (promise = check(/*matched*/ ctx[21])) && handle_promise(promise, info)) {
				
			} else {
				const child_ctx = ctx.slice();
				child_ctx[21] = info.resolved;
				info.block.p(child_ctx, dirty);
			}
		},
		d(detaching) {
			if (detaching) detach(await_block_anchor);
			info.block.d(detaching);
			info.token = null;
			info = null;
		}
	};
}

// (335:4) {#each Object.entries(awards) as [fan, rules]}
function create_each_block(ctx) {
	let each_1_anchor;
	let each_value_1 = Object.entries(/*rules*/ ctx[15]);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*check, Object, awards*/ 2) {
				each_value_1 = Object.entries(/*rules*/ ctx[15]);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let h1;
	let t0_value = /*$store*/ ctx[0][/*$store*/ ctx[0].turn].name + "";
	let t0;
	let t1;
	let t2;
	let div0;
	let t4;
	let div1;
	let t5;
	let button;
	let dispose;
	let each_value = Object.entries(/*awards*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div2 = element("div");
			h1 = element("h1");
			t0 = text(t0_value);
			t1 = text(" wins");
			t2 = space();
			div0 = element("div");
			div0.textContent = "Not all scores can be counted automatically at this time. This is very work in progress.\n    I will eventually try to make this fancier";
			t4 = space();
			div1 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t5 = space();
			button = element("button");
			button.textContent = "Play Again";
			attr(h1, "class", "title svelte-1sp7fjp");
			attr(div0, "class", "warning svelte-1sp7fjp");
			attr(div1, "class", "scores svelte-1sp7fjp");
			attr(button, "class", "play-again svelte-1sp7fjp");
			attr(div2, "class", "container svelte-1sp7fjp");
		},
		m(target, anchor, remount) {
			insert(target, div2, anchor);
			append(div2, h1);
			append(h1, t0);
			append(h1, t1);
			append(div2, t2);
			append(div2, div0);
			append(div2, t4);
			append(div2, div1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div1, null);
			}

			append(div2, t5);
			append(div2, button);
			if (remount) dispose();
			dispose = listen(button, "click", /*playAgain*/ ctx[3]);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$store*/ 1 && t0_value !== (t0_value = /*$store*/ ctx[0][/*$store*/ ctx[0].turn].name + "")) set_data(t0, t0_value);

			if (dirty & /*Object, awards, check*/ 2) {
				each_value = Object.entries(/*awards*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			destroy_each(each_blocks, detaching);
			dispose();
		}
	};
}

function check(value) {
	if (typeof value === "function") return value();
	return value;
}

function instance($$self, $$props, $$invalidate) {
	let $store;
	const { store, socket } = context();
	component_subscribe($$self, store, value => $$invalidate(0, $store = value));

	const eq = ({ ...lhs }, { ...rhs }, ctx = {}) => {
		if (typeof lhs.suit === "symbol") {
			if (!ctx[lhs.suit]) ctx[lhs.suit] = rhs.suit;
			lhs.suit = ctx[lhs.suit];
		} else if (typeof lhs.suit === "undefined") {
			delete rhs.suit;
		}

		if (typeof lhs.value === "symbol") {
			if (!ctx[lhs.value]) ctx[lhs.value] = rhs.value;
			lhs.value = ctx[lhs.value];
		} else if (typeof lhs.value === "undefined") {
			delete rhs.value;
		}

		return lhs.suit === rhs.suit && lhs.value === rhs.value;
	};

	const tiles = (suit, ...values) => values.map(value => ({ suit, value }));
	const pong = (suit, value) => tiles(suit, value, value, value);
	const ch = i => ["一", "二", "三", "四", "五", "六", "七", "八", "九"][i];
	const ro = i => ["Ya", "E", "Sam", "Sei", "M", "Lok", "Tsut", "Ba", "Gao"][i];

	async function playAgain() {
		const { schema } = await socket.send("playAgain");
		set_store_value(store, $store = new Schema(schema));
	}

	let winner;
	let winningHand;
	let winningSuits;
	let includes;
	let awards;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$store*/ 1) {
			$: $$invalidate(4, winner = $store[$store.turn]);
		}

		if ($$self.$$.dirty & /*winner, $store*/ 17) {
			$: $$invalidate(5, winningHand = [...winner.up, ...winner.down.flat()].filter(tile => typeof tile === "number").map(tile => $store.tiles[tile]));
		}

		if ($$self.$$.dirty & /*winningHand*/ 32) {
			$: $$invalidate(6, winningSuits = new Set(winningHand.map(tile => tile.suit)));
		}

		if ($$self.$$.dirty & /*winningHand, includes*/ 160) {
			$: $$invalidate(7, includes = (tiles, hand = [...winningHand], context = {}) => {
				if (tiles.length === 0) return true;
				const [tile, ...rest] = tiles;

				return hand.some((t, i) => {
					const ctx = { ...context };

					if (eq(tile, t, ctx)) {
						if (includes(rest, [...hand.slice(0, i), ...hand.slice(i + 1)], ctx)) {
							return true;
						}
					}
				});
			});
		}

		if ($$self.$$.dirty & /*includes, winner, $store, winningSuits, awards*/ 211) {
			// TODO: Will this be sufficient, or will going all Prolog be easier?
			$: $$invalidate(1, awards = {
				1: {
					"白板": {
						romanized: "Ba Ban",
						description: "Pong ba ban",
						matched: includes(pong("dragon", "Haku"))
					},
					"全求人": {
						romanized: "Chun Cao Yun",
						description: "All outside",
						matched: winner.up.length === 0
					},
					"獨聽": {
						romanized: "Doc Ting",
						description: "Calling one card",
						matched: false, // TODO: this one is harder, requires analyzing the hand
						
					},
					"發財": {
						romanized: "Fa Tsai",
						description: "Pong fa tsai",
						matched: includes(pong("dragon", "Hatsu"))
					},
					"槓上花": {
						romanized: "Gong Tsern Fa",
						description: "Win off gong (pick up gong, win off of card picked up as a result of gong)",
						matched: $store.source === "back"
					},
					"紅中": {
						romanized: "Hong Tsong",
						description: "Pong hong tsong",
						matched: includes(pong("dragon", "Chun"))
					},
					"圈風": {
						romanized: "Hyun Feng",
						description: "Pong the round wind",
						matched: includes(pong("wind", $store.wind))
					},
					"缺一门": {
						romanized: "Ku Ye Mun",
						description: "Two numerical suits",
						matched: winningSuits.size === 2 && !winningSuits.has("dragon") && !winningSuits.has("wind")
					},
					"老小": {
						romanized: "Lo Siu",
						description: "Chow of ends of same suit",
						matched: includes(tiles(Symbol("A"), 1, 2, 3, 7, 8, 9))
					},
					"門風": {
						romanized: "Mun Feng",
						description: "Pong the wind depending on where you sit (jong is 東)",
						matched: includes(tiles("wind", $store.turn))
					},
					"没字没花": {
						romanized: "Mo Zi Mo Fa",
						description: "No flowers, no winds",
						matched: false, // TODO: we don't have flowers, so I don't think this one is fair
						
					},
					"門前清": {
						romanized: "Mun Tsing Tsing",
						description: "All inside but win off of a played card",
						matched: winner.down.length === 1 && ($store.source === "discard" || $store.source === "kong")
					},
					"平糊": {
						romanized: "Ping Wu",
						description: "All chows",
						matched: false, // TODO: better matching
						
					},
					"爵": {
						romanized: "Tsern",
						description: "Pair of eyes (2, 5, 8)",
						matched: false, // TODO: better matching
						
					},
					"自摸": {
						romanized: "Tsi Mo",
						description: "Self touch",
						matched: $store.source === "front" || $store.source === "back"
					},
					"姐妹": {
						romanized: "Tsi Mui",
						description: "Pair of same chow of different suits",
						matched: false, // TODO: better matching
						
					},
					"断优": {
						romanized: "Tsun Yu",
						description: "No ends",
						matched: !includes([{ suit: Symbol("A"), value: 1 }]) && !includes([{ suit: Symbol("A"), value: 9 }])
					},
					"搶槓": {
						romanized: "Tsurng Gong",
						description: "Steal from another person's gong to win",
						matched: $store.source === "kong"
					},
					"一条龙": {
						romanized: "Ya Tiu Long",
						description: "Dragon (2 suits)",
						matched: false, // TODO: better matching
						
					}
				},
				2: {
					"混优": {
						romanized: "Wan Yu",
						description: "All ends with winds",
						matched: false, // TODO: better matching
						
					},
					"一班高": {
						romanized: "Ye Ban Go",
						description: "Two of the same chow, in the same suit",
						matched: false, // TODO: better matching
						
					},
					"一条龙": {
						romanized: "Ya Tiu Long",
						description: "Dragon (3 suits)",
						matched: false, // TODO: better matching
						
					}
				},
				3: {
					"對對糊": {
						romanized: "De De Wu",
						description: "All pongs",
						matched: false, // TODO: better matching
						
					},
					"雞糊": {
						romanized: "Gai Wu",
						description: "Chicken hand, zero fans",
						matched: () => Object.values(awards).flatMap(rules => Object.values(rules)).map(rule => rule.matched).filter(rule => typeof rule !== "function").reduce(async (first, next) => await first && !await next, Promise.resolve(true))
					},
					"五门齐": {
						romanized: "M Mun Tsai",
						description: "All five suits",
						matched: winningSuits.size === 5
					},
					"四相凤": {
						romanized: "Sam Tsern Vong",
						description: "Same chow of all three suits (can pong three sequential numbers for 1 limit)",
						matched: false, // TODO: better matching
						
					},
					"全带优": {
						romanized: "Tsun Dai Yu",
						description: "All ends without winds",
						matched: false, // TODO: better matching
						
					},
					"混一色": {
						romanized: "Wan Ya Se",
						description: "All one suit with winds",
						matched: winningSuits.size === 2 && winningSuits.has("wind") && !winningSuits.has("dragon")
					},
					"一条龙": {
						romanized: "Ya Tiu Long",
						description: "Dragon (1 suit)",
						matched: includes(tiles(Symbol("A"), 1, 2, 3, 4, 5, 6, 7, 8, 9))
					},
					"一摸三": {
						romanized: "Ya Mo Sam",
						description: "All inside with self touch",
						matched: winner.down.length === 0
					}
				},
				5: {
					"小三元": {
						romanized: "Siu Sam Yu",
						description: "Pong of any two of tsong, fa, ba ban with the third as eyes",
						matched: false, // TODO: better matching
						
					},
					"小七对": {
						romanized: "Siu Tsut Doi",
						description: "All pairs",
						matched: false, // TODO: special case?
						
					}
				},
				8: {
					"坎坎糊": {
						romanized: "Can Can Wu",
						description: "All pongs but all inside, must be calling eyes unless you self touch",
						matched: false, // TODO: better matching
						
					},
					"大三元": {
						romanized: "Da Sam Yu",
						description: "Pong tsong, fa, and ban",
						matched: includes([
							...pong("dragon", "Haku"),
							...pong("dragon", "Hatsu"),
							...pong("dragon", "Chun")
						])
					},
					"大七对": {
						romanized: "Dai Tsut Doi",
						description: "Two ya ban go's",
						matched: false, // TODO: better matching
						
					},
					"小四喜": {
						romanized: "Siu Sei Hei",
						description: "Pong 3 winds with last as eyes",
						matched: false, // TODO: better matching
						
					},
					"清一色": {
						romanized: "Ting Ya Se",
						description: "All one numerical suit",
						matched: winningSuits.size === 1 && !winningSuits.has("wind") && !winningSuits.has("dragon")
					},
					...Object.fromEntries([1, 2, 3, 4, 5, 6, 7, 8, 9].map(value => [
						`全带${ch(value)}`,
						{
							romanized: `Tsun Dai ${ro(value)}`,
							description: `All ${value}`,
							matched: includes(tiles(undefined, value, value, value, value, value, value, value, value, value, value, value, value, value, value, value))
						}
					]))
				},
				11: {
					"全绿": {
						romanized: "Chuen Lo",
						description: "All green",
						matched: false, // TODO: sticks 2, 3, 4, 6, 8 + salad
						
					},
					"大四喜": {
						romanized: "Da Sei Hei",
						description: "Pong all winds (东 南 西 北)",
						matched: includes([
							...pong("wind", "Ton"),
							...pong("wind", "Nan"),
							...pong("wind", "Shaa"),
							...pong("wind", "Pei")
						])
					},
					"地糊": {
						romanized: "Dei Wu",
						description: "Win off first card played",
						matched: false, // TODO: count how many cards have been played
						
					},
					"一四七": {
						romanized: "Ya Sei Tsut",
						description: "Pong any and only 1, 4, 7",
						matched: false, // TODO: better matching
						
					},
					"二五八": {
						romanized: "E M Ba",
						description: "Pong any and only 2, 5, 8",
						matched: false, // TODO: better matching
						
					},
					"三六九": {
						romanized: "Sam Lok Gao",
						description: "Pong any and only 3, 6, 9",
						matched: false, // TODO: better matching
						
					},
					"十三不答": {
						romanized: "Sap Sam Ba Da",
						description: "Start the hand with zero connections, first card played cannot connect to anything in the hand",
						matched: false, // TODO: special case
						
					},
					"十三大优": {
						romanized: "Sap Sam Dai Yu",
						description: "Have 1 and 9 of all suits, one of each wind and a pair of anything",
						matched: false, // TODO: better matching
						
					},
					"天糊": {
						romanized: "Tian Wu",
						description: "Be the starter (jong) and win off the very start",
						matched: false, // TODO: count how many cards have been played
						
					},
					"字一色": {
						romanized: "Zi Ya Se",
						description: "Pong all winds (东 南 四 北 + tsong, fa, ba ban)",
						matched: !winningSuits.has("Pin") && !winningSuits.has("Sou") && !winningSuits.has("Man")
					}
				},
				[-11]: {
					"詐糊": {
						romanized: "Za Wu",
						description: "Falsely claim that you have won, must pay everyone the maximum",
						matched: false, // TODO: foxfriends/mahjong#7
						
					}
				}
			});
		}
	};

	return [$store, awards, store, playAgain];
}

class GameEnd extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1sp7fjp-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default GameEnd;