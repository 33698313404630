/* app/Title.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_slot_changes,
	get_slot_context,
	init,
	insert,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-noi0ui-style";
	style.textContent = ".container.svelte-noi0ui{margin:100px}.title.svelte-noi0ui,.subtitle.svelte-noi0ui{font-family:var(--font-chinese);text-align:center;margin:0}.title.svelte-noi0ui{font-size:48pt}.subtitle.svelte-noi0ui{font-size:24pt}";
	append(document.head, style);
}

function create_fragment(ctx) {
	let div;
	let h1;
	let t1;
	let h2;
	let t3;
	let current;
	const default_slot_template = /*$$slots*/ ctx[1].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[0], null);

	return {
		c() {
			div = element("div");
			h1 = element("h1");
			h1.textContent = "朋友一起打麻将";
			t1 = space();
			h2 = element("h2");
			h2.textContent = "Mahjong with Friends";
			t3 = space();
			if (default_slot) default_slot.c();
			attr(h1, "class", "title svelte-noi0ui");
			attr(h2, "class", "subtitle svelte-noi0ui");
			attr(div, "class", "container svelte-noi0ui");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h1);
			append(div, t1);
			append(div, h2);
			append(div, t3);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && dirty & /*$$scope*/ 1) {
					default_slot.p(get_slot_context(default_slot_template, ctx, /*$$scope*/ ctx[0], null), get_slot_changes(default_slot_template, /*$$scope*/ ctx[0], dirty, null));
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots = {}, $$scope } = $$props;

	$$self.$set = $$props => {
		if ("$$scope" in $$props) $$invalidate(0, $$scope = $$props.$$scope);
	};

	return [$$scope, $$slots];
}

class Title extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-noi0ui-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Title;