/* app/Status/ActionButtons.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

import TextTile from "./TextTile.svelte";
import Schema, { eq } from "../../lib/schema.js";
import context from "../../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-8dtpkt-style";
	style.textContent = ".container.svelte-8dtpkt{position:absolute;top:0;left:0;width:100vw;height:100vh;pointer-events:none}.actions.svelte-8dtpkt{position:absolute;bottom:50px;left:50px;display:flex;flex-direction:column;min-width:120px;pointer-events:none}.action.svelte-8dtpkt{background-color:rgb(255, 255, 255);border:rgba(255, 255, 255, 0.75);border-radius:4px;padding:8px;margin:16px;pointer-events:auto;font-size:18pt;cursor:pointer;display:flex;align-items:center;justify-content:center;word-wrap:no-wrap}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[23] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[23] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[28] = list[i];
	return child_ctx;
}

// (73:4) {#if $hasAction && $timer && !$currentVotes[myWind]}
function create_if_block_2(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (/*$timer*/ ctx[5].paused) return create_if_block_3;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (78:6) {:else}
function create_else_block(ctx) {
	let button;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Wait";
			attr(button, "class", "action svelte-8dtpkt");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			if (remount) dispose();
			dispose = listen(button, "click", /*wait*/ ctx[14]);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			dispose();
		}
	};
}

// (74:6) {#if $timer.paused}
function create_if_block_3(ctx) {
	let button;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Cancel Wait";
			attr(button, "class", "action svelte-8dtpkt");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			if (remount) dispose();
			dispose = listen(button, "click", /*cancel*/ ctx[15]);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			dispose();
		}
	};
}

// (85:4) {#each actions as action}
function create_each_block_2(ctx) {
	let button;
	let t_value = /*action*/ ctx[28].label + "";
	let t;
	let dispose;

	return {
		c() {
			button = element("button");
			t = text(t_value);
			attr(button, "class", "action svelte-8dtpkt");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			append(button, t);
			if (remount) dispose();

			dispose = listen(button, "click", function () {
				if (is_function(/*action*/ ctx[28].handler)) /*action*/ ctx[28].handler.apply(this, arguments);
			});
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*actions*/ 1 && t_value !== (t_value = /*action*/ ctx[28].label + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(button);
			dispose();
		}
	};
}

// (91:4) {#if $store && Schema.winningHand($store, $store[myWind]) && $store.turn === myWind}
function create_if_block_1(ctx) {
	let button;
	let dispose;

	return {
		c() {
			button = element("button");
			button.textContent = "Win";
			attr(button, "class", "action svelte-8dtpkt");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			if (remount) dispose();
			dispose = listen(button, "click", /*win*/ ctx[17]);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(button);
			dispose();
		}
	};
}

// (97:4) {#if $store && $store.drawn !== undefined && $store.turn === myWind}
function create_if_block(ctx) {
	let t;
	let each1_anchor;
	let current;
	let each_value_1 = /*concealedKongs*/ ctx[3];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks_1[i], 1, 1, () => {
		each_blocks_1[i] = null;
	});

	let each_value = /*exposedKongs*/ ctx[4];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out_1 = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(target, anchor);
			}

			insert(target, t, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*kong, concealedKongs*/ 65544) {
				each_value_1 = /*concealedKongs*/ ctx[3];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
						transition_in(each_blocks_1[i], 1);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						transition_in(each_blocks_1[i], 1);
						each_blocks_1[i].m(t.parentNode, t);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks_1.length; i += 1) {
					out(i);
				}

				check_outros();
			}

			if (dirty & /*kong, exposedKongs*/ 65552) {
				each_value = /*exposedKongs*/ ctx[4];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each1_anchor.parentNode, each1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out_1(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks_1[i]);
			}

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks_1 = each_blocks_1.filter(Boolean);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				transition_out(each_blocks_1[i]);
			}

			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks_1, detaching);
			if (detaching) detach(t);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each1_anchor);
		}
	};
}

// (98:6) {#each concealedKongs as tile}
function create_each_block_1(ctx) {
	let button;
	let t0;
	let t1;
	let current;
	let dispose;
	const texttile = new TextTile({ props: { tile: /*tile*/ ctx[23] } });

	function click_handler(...args) {
		return /*click_handler*/ ctx[21](/*tile*/ ctx[23], ...args);
	}

	return {
		c() {
			button = element("button");
			t0 = text("Kong (");
			create_component(texttile.$$.fragment);
			t1 = text(")");
			attr(button, "class", "action svelte-8dtpkt");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			append(button, t0);
			mount_component(texttile, button, null);
			append(button, t1);
			current = true;
			if (remount) dispose();
			dispose = listen(button, "click", click_handler);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const texttile_changes = {};
			if (dirty & /*concealedKongs*/ 8) texttile_changes.tile = /*tile*/ ctx[23];
			texttile.$set(texttile_changes);
		},
		i(local) {
			if (current) return;
			transition_in(texttile.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(texttile.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			destroy_component(texttile);
			dispose();
		}
	};
}

// (104:6) {#each exposedKongs as tile}
function create_each_block(ctx) {
	let button;
	let t0;
	let t1;
	let current;
	let dispose;
	const texttile = new TextTile({ props: { tile: /*tile*/ ctx[23] } });

	function click_handler_1(...args) {
		return /*click_handler_1*/ ctx[22](/*tile*/ ctx[23], ...args);
	}

	return {
		c() {
			button = element("button");
			t0 = text("Kong (");
			create_component(texttile.$$.fragment);
			t1 = text(")\n        ");
			attr(button, "class", "action svelte-8dtpkt");
		},
		m(target, anchor, remount) {
			insert(target, button, anchor);
			append(button, t0);
			mount_component(texttile, button, null);
			append(button, t1);
			current = true;
			if (remount) dispose();
			dispose = listen(button, "click", click_handler_1);
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const texttile_changes = {};
			if (dirty & /*exposedKongs*/ 16) texttile_changes.tile = /*tile*/ ctx[23];
			texttile.$set(texttile_changes);
		},
		i(local) {
			if (current) return;
			transition_in(texttile.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(texttile.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			destroy_component(texttile);
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let t0;
	let t1;
	let show_if = /*$store*/ ctx[2] && Schema.winningHand(/*$store*/ ctx[2], /*$store*/ ctx[2][/*myWind*/ ctx[1]]) && /*$store*/ ctx[2].turn === /*myWind*/ ctx[1];
	let t2;
	let current;
	let if_block0 = /*$hasAction*/ ctx[6] && /*$timer*/ ctx[5] && !/*$currentVotes*/ ctx[7][/*myWind*/ ctx[1]] && create_if_block_2(ctx);
	let each_value_2 = /*actions*/ ctx[0];
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let if_block1 = show_if && create_if_block_1(ctx);
	let if_block2 = /*$store*/ ctx[2] && /*$store*/ ctx[2].drawn !== undefined && /*$store*/ ctx[2].turn === /*myWind*/ ctx[1] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			attr(div0, "class", "actions svelte-8dtpkt");
			attr(div1, "class", "container svelte-8dtpkt");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			if (if_block0) if_block0.m(div0, null);
			append(div0, t0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			append(div0, t1);
			if (if_block1) if_block1.m(div0, null);
			append(div0, t2);
			if (if_block2) if_block2.m(div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$hasAction*/ ctx[6] && /*$timer*/ ctx[5] && !/*$currentVotes*/ ctx[7][/*myWind*/ ctx[1]]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(div0, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty & /*actions*/ 1) {
				each_value_2 = /*actions*/ ctx[0];
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, t1);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}

			if (dirty & /*$store, myWind*/ 6) show_if = /*$store*/ ctx[2] && Schema.winningHand(/*$store*/ ctx[2], /*$store*/ ctx[2][/*myWind*/ ctx[1]]) && /*$store*/ ctx[2].turn === /*myWind*/ ctx[1];

			if (show_if) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div0, t2);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*$store*/ ctx[2] && /*$store*/ ctx[2].drawn !== undefined && /*$store*/ ctx[2].turn === /*myWind*/ ctx[1]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
					transition_in(if_block2, 1);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(div0, null);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block2);
			current = true;
		},
		o(local) {
			transition_out(if_block2);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();
			destroy_each(each_blocks, detaching);
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $selectionSets;
	let $selection;
	let $store;
	let $timer;
	let $hasAction;
	let $currentVotes;
	const { currentVotes, selection, selectionSets, socket, store, hasAction, timer } = context();
	component_subscribe($$self, currentVotes, value => $$invalidate(7, $currentVotes = value));
	component_subscribe($$self, selection, value => $$invalidate(19, $selection = value));
	component_subscribe($$self, selectionSets, value => $$invalidate(18, $selectionSets = value));
	component_subscribe($$self, store, value => $$invalidate(2, $store = value));
	component_subscribe($$self, hasAction, value => $$invalidate(6, $hasAction = value));
	component_subscribe($$self, timer, value => $$invalidate(5, $timer = value));
	let actions = [];

	function wait() {
		set_store_value(timer, $timer.paused = Date.now(), $timer);

		if ($timer.handle) {
			window.clearTimeout($timer.handle);
			set_store_value(timer, $timer.handle = false, $timer);
		}
	}

	async function cancel() {
		await socket.send("ignore");
	}

	async function kong(mode, tile) {
		try {
			await socket.send("kong", { mode, tile });
		} catch(error) {
			console.log(error);
		}
	}

	async function win() {
		try {
			await socket.send("declare");
		} catch(error) {
			console.log(error);
		}
	}

	const click_handler = tile => kong("concealed", tile);
	const click_handler_1 = tile => kong("augmented", tile);
	let myWind;
	let concealedKongs;
	let exposedKongs;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$selectionSets, $selection*/ 786432) {
			$: $$invalidate(0, actions = $selectionSets.filter(selectionSet => {
				return selectionSet.tiles.every(tile => $selection.has(tile)) && selectionSet.tiles.length === $selection.size;
			}));
		}

		if ($$self.$$.dirty & /*$store*/ 4) {
			$: $$invalidate(1, myWind = $store && $store.playerWind(socket.name));
		}

		if ($$self.$$.dirty & /*$store, myWind*/ 6) {
			$: $$invalidate(3, concealedKongs = $store[myWind].up.filter((tile, i, tiles) => tiles.slice(i + 1).map(tile => $store.tiles[tile]).filter(info => eq(info, $store.tiles[tile])).length === 3));
		}

		if ($$self.$$.dirty & /*$store, myWind*/ 6) {
			$: $$invalidate(4, exposedKongs = $store[myWind].up.filter(tile => $store[myWind].down.filter(meld => meld.length === 3).some(meld => meld.map(tile => $store.tiles[tile]).every(info => eq(info, $store.tiles[tile])))));
		}
	};

	return [
		actions,
		myWind,
		$store,
		concealedKongs,
		exposedKongs,
		$timer,
		$hasAction,
		$currentVotes,
		currentVotes,
		selection,
		selectionSets,
		store,
		hasAction,
		timer,
		wait,
		cancel,
		kong,
		win,
		$selectionSets,
		$selection,
		socket,
		click_handler,
		click_handler_1
	];
}

class ActionButtons extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-8dtpkt-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ActionButtons;