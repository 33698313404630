/* app/Table.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_slot_changes,
	get_slot_context,
	init,
	insert,
	safe_not_equal,
	set_data,
	set_style,
	space,
	text,
	transition_in,
	transition_out
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-f9ywgj-style";
	style.textContent = ".world.svelte-f9ywgj{position:relative;perspective:120vh;width:100%;height:100%;overflow:hidden}.table.svelte-f9ywgj{position:relative;width:min(100vw, 100vh);height:min(100vw, 100vh);margin:0 auto;background-image:var(--image-table);transform-style:preserve-3d;transition:transform 2s;will-change:transform}.top-edge.svelte-f9ywgj,.bottom-edge.svelte-f9ywgj{position:absolute;left:0;width:100%;height:0.5cm;background-color:#2c4730}.top-edge.svelte-f9ywgj{bottom:100%;transform-origin:bottom;transform:rotateX(-90deg)}.bottom-edge.svelte-f9ywgj{top:100%;transform-origin:top;transform:rotateX(90deg)}.left-edge.svelte-f9ywgj,.right-edge.svelte-f9ywgj{position:absolute;top:0;width:0.5cm;height:100%;background-color:#264231}.left-edge.svelte-f9ywgj{right:100%;transform-origin:right;transform:rotateY(90deg)}.top-label.svelte-f9ywgj,.bottom-label.svelte-f9ywgj,.right-label.svelte-f9ywgj,.left-label.svelte-f9ywgj{padding:40px 0;color:white;font-size:60pt;font-family:var(--font-english);text-align:center}.top-label.svelte-f9ywgj::before,.bottom-label.svelte-f9ywgj::before,.right-label.svelte-f9ywgj::before,.left-label.svelte-f9ywgj::before{font-family:var(--font-chinese);margin-right:40px}.bottom-label.svelte-f9ywgj::before{content:'东'}.top-label.svelte-f9ywgj::before{content:'西'}.right-label.svelte-f9ywgj::before{content:'南'}.left-label.svelte-f9ywgj::before{content:'北'}.right-edge.svelte-f9ywgj{left:100%;transform-origin:left;transform:rotateY(-90deg)}.left-label.svelte-f9ywgj{position:absolute;bottom:0;left:0;width:100%;transform-origin:bottom left;transform:rotateZ(-90deg)}.right-label.svelte-f9ywgj{position:absolute;bottom:0;right:0;width:100%;transform-origin:bottom right;transform:rotateZ(90deg)}.top-label.svelte-f9ywgj{position:absolute;bottom:100%;left:0;width:100%}.bottom-label.svelte-f9ywgj{position:absolute;top:100%;left:0;width:100%;transform-origin:center;transform:rotateZ(180deg)}.highlight.svelte-f9ywgj{text-decoration:underline}";
	append(document.head, style);
}

// (9:4) {#if topLabel}
function create_if_block_3(ctx) {
	let div;
	let t;
	let div_class_value;

	return {
		c() {
			div = element("div");
			t = text(/*topLabel*/ ctx[2]);
			attr(div, "class", div_class_value = "top-label " + (/*highlightSide*/ ctx[6] === "top" ? "highlight" : "") + " svelte-f9ywgj");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*topLabel*/ 4) set_data(t, /*topLabel*/ ctx[2]);

			if (dirty & /*highlightSide*/ 64 && div_class_value !== (div_class_value = "top-label " + (/*highlightSide*/ ctx[6] === "top" ? "highlight" : "") + " svelte-f9ywgj")) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (12:4) {#if leftLabel}
function create_if_block_2(ctx) {
	let div;
	let t;
	let div_class_value;

	return {
		c() {
			div = element("div");
			t = text(/*leftLabel*/ ctx[3]);
			attr(div, "class", div_class_value = "left-label " + (/*highlightSide*/ ctx[6] === "left" ? "highlight" : "") + " svelte-f9ywgj");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*leftLabel*/ 8) set_data(t, /*leftLabel*/ ctx[3]);

			if (dirty & /*highlightSide*/ 64 && div_class_value !== (div_class_value = "left-label " + (/*highlightSide*/ ctx[6] === "left" ? "highlight" : "") + " svelte-f9ywgj")) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (15:4) {#if rightLabel}
function create_if_block_1(ctx) {
	let div;
	let t;
	let div_class_value;

	return {
		c() {
			div = element("div");
			t = text(/*rightLabel*/ ctx[4]);
			attr(div, "class", div_class_value = "right-label " + (/*highlightSide*/ ctx[6] === "right" ? "highlight" : "") + " svelte-f9ywgj");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*rightLabel*/ 16) set_data(t, /*rightLabel*/ ctx[4]);

			if (dirty & /*highlightSide*/ 64 && div_class_value !== (div_class_value = "right-label " + (/*highlightSide*/ ctx[6] === "right" ? "highlight" : "") + " svelte-f9ywgj")) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (18:4) {#if bottomLabel}
function create_if_block(ctx) {
	let div;
	let t;
	let div_class_value;

	return {
		c() {
			div = element("div");
			t = text(/*bottomLabel*/ ctx[5]);
			attr(div, "class", div_class_value = "bottom-label " + (/*highlightSide*/ ctx[6] === "bottom" ? "highlight" : "") + " svelte-f9ywgj");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t);
		},
		p(ctx, dirty) {
			if (dirty & /*bottomLabel*/ 32) set_data(t, /*bottomLabel*/ ctx[5]);

			if (dirty & /*highlightSide*/ 64 && div_class_value !== (div_class_value = "bottom-label " + (/*highlightSide*/ ctx[6] === "bottom" ? "highlight" : "") + " svelte-f9ywgj")) {
				attr(div, "class", div_class_value);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let div5;
	let div4;
	let t0;
	let t1;
	let t2;
	let t3;
	let div0;
	let t4;
	let div1;
	let t5;
	let div2;
	let t6;
	let div3;
	let t7;
	let current;
	let if_block0 = /*topLabel*/ ctx[2] && create_if_block_3(ctx);
	let if_block1 = /*leftLabel*/ ctx[3] && create_if_block_2(ctx);
	let if_block2 = /*rightLabel*/ ctx[4] && create_if_block_1(ctx);
	let if_block3 = /*bottomLabel*/ ctx[5] && create_if_block(ctx);
	const default_slot_template = /*$$slots*/ ctx[8].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[7], null);

	return {
		c() {
			div5 = element("div");
			div4 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			if (if_block1) if_block1.c();
			t1 = space();
			if (if_block2) if_block2.c();
			t2 = space();
			if (if_block3) if_block3.c();
			t3 = space();
			div0 = element("div");
			t4 = space();
			div1 = element("div");
			t5 = space();
			div2 = element("div");
			t6 = space();
			div3 = element("div");
			t7 = space();
			if (default_slot) default_slot.c();
			attr(div0, "class", "top-edge svelte-f9ywgj");
			attr(div1, "class", "left-edge svelte-f9ywgj");
			attr(div2, "class", "right-edge svelte-f9ywgj");
			attr(div3, "class", "bottom-edge svelte-f9ywgj");
			attr(div4, "class", "table svelte-f9ywgj");
			set_style(div4, "transform", "rotateX(" + /*angle*/ ctx[0] + "deg) rotateZ(" + /*rotation*/ ctx[1] + "deg)");
			attr(div5, "class", "world svelte-f9ywgj");
		},
		m(target, anchor) {
			insert(target, div5, anchor);
			append(div5, div4);
			if (if_block0) if_block0.m(div4, null);
			append(div4, t0);
			if (if_block1) if_block1.m(div4, null);
			append(div4, t1);
			if (if_block2) if_block2.m(div4, null);
			append(div4, t2);
			if (if_block3) if_block3.m(div4, null);
			append(div4, t3);
			append(div4, div0);
			append(div4, t4);
			append(div4, div1);
			append(div4, t5);
			append(div4, div2);
			append(div4, t6);
			append(div4, div3);
			append(div4, t7);

			if (default_slot) {
				default_slot.m(div4, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (/*topLabel*/ ctx[2]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_3(ctx);
					if_block0.c();
					if_block0.m(div4, t0);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*leftLabel*/ ctx[3]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					if_block1.m(div4, t1);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*rightLabel*/ ctx[4]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					if_block2.m(div4, t2);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*bottomLabel*/ ctx[5]) {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block(ctx);
					if_block3.c();
					if_block3.m(div4, t3);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (default_slot) {
				if (default_slot.p && dirty & /*$$scope*/ 128) {
					default_slot.p(get_slot_context(default_slot_template, ctx, /*$$scope*/ ctx[7], null), get_slot_changes(default_slot_template, /*$$scope*/ ctx[7], dirty, null));
				}
			}

			if (!current || dirty & /*angle, rotation*/ 3) {
				set_style(div4, "transform", "rotateX(" + /*angle*/ ctx[0] + "deg) rotateZ(" + /*rotation*/ ctx[1] + "deg)");
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div5);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { angle = 0 } = $$props, { rotation = 0 } = $$props;

	let { topLabel = "" } = $$props,
		{ leftLabel = "" } = $$props,
		{ rightLabel = "" } = $$props,
		{ bottomLabel = "" } = $$props;

	let { highlightSide = null } = $$props;
	let { $$slots = {}, $$scope } = $$props;

	$$self.$set = $$props => {
		if ("angle" in $$props) $$invalidate(0, angle = $$props.angle);
		if ("rotation" in $$props) $$invalidate(1, rotation = $$props.rotation);
		if ("topLabel" in $$props) $$invalidate(2, topLabel = $$props.topLabel);
		if ("leftLabel" in $$props) $$invalidate(3, leftLabel = $$props.leftLabel);
		if ("rightLabel" in $$props) $$invalidate(4, rightLabel = $$props.rightLabel);
		if ("bottomLabel" in $$props) $$invalidate(5, bottomLabel = $$props.bottomLabel);
		if ("highlightSide" in $$props) $$invalidate(6, highlightSide = $$props.highlightSide);
		if ("$$scope" in $$props) $$invalidate(7, $$scope = $$props.$$scope);
	};

	return [
		angle,
		rotation,
		topLabel,
		leftLabel,
		rightLabel,
		bottomLabel,
		highlightSide,
		$$scope,
		$$slots
	];
}

class Table extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-f9ywgj-style")) add_css();

		init(this, options, instance, create_fragment, safe_not_equal, {
			angle: 0,
			rotation: 1,
			topLabel: 2,
			leftLabel: 3,
			rightLabel: 4,
			bottomLabel: 5,
			highlightSide: 6
		});
	}
}

export default Table;