/* app/Status/CurrentVotes.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

import context from "../../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-1swhj85-style";
	style.textContent = ".votes.svelte-1swhj85{pointer-events:none;position:absolute;top:100vh;left:100vw;padding:32px;transform:translate(-100%, -100%);display:flex;flex-direction:column;align-items:flex-start}.vote.svelte-1swhj85{white-space:nowrap;font-size:18pt;color:white;font-family:var(--font-english);margin-left:auto}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i][0];
	child_ctx[7] = list[i][1];
	return child_ctx;
}

// (7:0) {#if $timer}
function create_if_block(ctx) {
	let div;
	let each_value = Object.entries(/*$currentVotes*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div, "class", "votes svelte-1swhj85");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*Object, $currentVotes, $store*/ 6) {
				each_value = Object.entries(/*$currentVotes*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (10:8) {#if vote && vote.method !== 'Discard' && vote.method !== 'Ignore'}
function create_if_block_1(ctx) {
	let div;
	let t0_value = /*$store*/ ctx[2][/*position*/ ctx[6]].name + "";
	let t0;
	let t1;
	let t2_value = /*vote*/ ctx[7].method + "";
	let t2;
	let t3;

	return {
		c() {
			div = element("div");
			t0 = text(t0_value);
			t1 = text(": ");
			t2 = text(t2_value);
			t3 = space();
			attr(div, "class", "vote svelte-1swhj85");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, t0);
			append(div, t1);
			append(div, t2);
			append(div, t3);
		},
		p(ctx, dirty) {
			if (dirty & /*$store, $currentVotes*/ 6 && t0_value !== (t0_value = /*$store*/ ctx[2][/*position*/ ctx[6]].name + "")) set_data(t0, t0_value);
			if (dirty & /*$currentVotes*/ 2 && t2_value !== (t2_value = /*vote*/ ctx[7].method + "")) set_data(t2, t2_value);
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

// (9:6) {#each Object.entries($currentVotes) as [position, vote]}
function create_each_block(ctx) {
	let if_block_anchor;
	let if_block = /*vote*/ ctx[7] && /*vote*/ ctx[7].method !== "Discard" && /*vote*/ ctx[7].method !== "Ignore" && create_if_block_1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if (/*vote*/ ctx[7] && /*vote*/ ctx[7].method !== "Discard" && /*vote*/ ctx[7].method !== "Ignore") {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*$timer*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*$timer*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $timer;
	let $currentVotes;
	let $store;
	const { store, currentVotes, timer } = context();
	component_subscribe($$self, store, value => $$invalidate(2, $store = value));
	component_subscribe($$self, currentVotes, value => $$invalidate(1, $currentVotes = value));
	component_subscribe($$self, timer, value => $$invalidate(0, $timer = value));
	return [$timer, $currentVotes, $store, store, currentVotes, timer];
}

class CurrentVotes extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-1swhj85-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default CurrentVotes;