/* app/Status/Timer.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_style,
	toggle_class
} from "svelte/internal";

import { readable } from "svelte/store";
import context from "../../game/context.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-18p9mny-style";
	style.textContent = ".timer.svelte-18p9mny{position:absolute;top:0;left:0;height:16px;background-color:#89abe3;pointer-events:none}.timer.paused.svelte-18p9mny{background-color:#7bc77e}";
	append(document.head, style);
}

// (19:0) {#if $timer}
function create_if_block(ctx) {
	let div;

	return {
		c() {
			div = element("div");
			attr(div, "class", "timer svelte-18p9mny");
			set_style(div, "width", Math.min(100, ((/*$timer*/ ctx[0].paused || /*$time*/ ctx[1]) - /*$timer*/ ctx[0].start) / /*$timer*/ ctx[0].duration * 100) + "vw");
			toggle_class(div, "paused", /*$timer*/ ctx[0].paused);
		},
		m(target, anchor) {
			insert(target, div, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*$timer, $time*/ 3) {
				set_style(div, "width", Math.min(100, ((/*$timer*/ ctx[0].paused || /*$time*/ ctx[1]) - /*$timer*/ ctx[0].start) / /*$timer*/ ctx[0].duration * 100) + "vw");
			}

			if (dirty & /*$timer*/ 1) {
				toggle_class(div, "paused", /*$timer*/ ctx[0].paused);
			}
		},
		d(detaching) {
			if (detaching) detach(div);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*$timer*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*$timer*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

const time = readable(Date.now(), set => {
	function update() {
		set(Date.now());
		window.requestAnimationFrame(update);
	}

	window.requestAnimationFrame(update);
});

function instance($$self, $$props, $$invalidate) {
	let $timer;
	let $time;
	component_subscribe($$self, time, $$value => $$invalidate(1, $time = $$value));
	const { timer } = context();
	component_subscribe($$self, timer, value => $$invalidate(0, $timer = value));
	return [$timer, $time, timer];
}

class Timer extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-18p9mny-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Timer;