/* app/Status/Status.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import ActionButtons from "./ActionButtons.svelte";
import GameEnd from "./GameEnd.svelte";
import PlayerList from "./PlayerList.svelte";
import ReadyButton from "./ReadyButton.svelte";
import DiscardInfo from "./DiscardInfo.svelte";
import CurrentWind from "./CurrentWind.svelte";
import CurrentVotes from "./CurrentVotes.svelte";
import Timer from "./Timer.svelte";
import context from "../../game/context.js";

function create_else_block_1(ctx) {
	let t;
	let current;

	const playerlist = new PlayerList({
			props: {
				order: /*ORDER*/ ctx[3][/*$store*/ ctx[0].playerWind(/*socket*/ ctx[1].name)]
			}
		});

	const readybutton = new ReadyButton({});

	return {
		c() {
			create_component(playerlist.$$.fragment);
			t = space();
			create_component(readybutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(playerlist, target, anchor);
			insert(target, t, anchor);
			mount_component(readybutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const playerlist_changes = {};
			if (dirty & /*$store*/ 1) playerlist_changes.order = /*ORDER*/ ctx[3][/*$store*/ ctx[0].playerWind(/*socket*/ ctx[1].name)];
			playerlist.$set(playerlist_changes);
		},
		i(local) {
			if (current) return;
			transition_in(playerlist.$$.fragment, local);
			transition_in(readybutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(playerlist.$$.fragment, local);
			transition_out(readybutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(playerlist, detaching);
			if (detaching) detach(t);
			destroy_component(readybutton, detaching);
		}
	};
}

// (22:0) {#if $store.started}
function create_if_block(ctx) {
	let t0;
	let current_block_type_index;
	let if_block0;
	let t1;
	let t2;
	let t3;
	let current;
	const timer = new Timer({});
	const if_block_creators = [create_if_block_3, create_else_block];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (!/*$store*/ ctx[0].completed) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let if_block1 = /*$store*/ ctx[0].discarded !== undefined && create_if_block_2(ctx);
	let if_block2 = /*$store*/ ctx[0].wind && create_if_block_1(ctx);
	const currentvotes = new CurrentVotes({});

	return {
		c() {
			create_component(timer.$$.fragment);
			t0 = space();
			if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			t3 = space();
			create_component(currentvotes.$$.fragment);
		},
		m(target, anchor) {
			mount_component(timer, target, anchor);
			insert(target, t0, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t2, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, t3, anchor);
			mount_component(currentvotes, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index !== previous_block_index) {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block0 = if_blocks[current_block_type_index];

				if (!if_block0) {
					if_block0 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block0.c();
				}

				transition_in(if_block0, 1);
				if_block0.m(t1.parentNode, t1);
			}

			if (/*$store*/ ctx[0].discarded !== undefined) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
					transition_in(if_block1, 1);
				} else {
					if_block1 = create_if_block_2(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(t2.parentNode, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*$store*/ ctx[0].wind) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
					transition_in(if_block2, 1);
				} else {
					if_block2 = create_if_block_1(ctx);
					if_block2.c();
					transition_in(if_block2, 1);
					if_block2.m(t3.parentNode, t3);
				}
			} else if (if_block2) {
				group_outros();

				transition_out(if_block2, 1, 1, () => {
					if_block2 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(timer.$$.fragment, local);
			transition_in(if_block0);
			transition_in(if_block1);
			transition_in(if_block2);
			transition_in(currentvotes.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(timer.$$.fragment, local);
			transition_out(if_block0);
			transition_out(if_block1);
			transition_out(if_block2);
			transition_out(currentvotes.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(timer, detaching);
			if (detaching) detach(t0);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t2);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(t3);
			destroy_component(currentvotes, detaching);
		}
	};
}

// (26:2) {:else}
function create_else_block(ctx) {
	let current;
	const gameend = new GameEnd({});

	return {
		c() {
			create_component(gameend.$$.fragment);
		},
		m(target, anchor) {
			mount_component(gameend, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(gameend.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(gameend.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(gameend, detaching);
		}
	};
}

// (24:2) {#if !$store.completed}
function create_if_block_3(ctx) {
	let current;
	const actionbuttons = new ActionButtons({});

	return {
		c() {
			create_component(actionbuttons.$$.fragment);
		},
		m(target, anchor) {
			mount_component(actionbuttons, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(actionbuttons.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(actionbuttons.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(actionbuttons, detaching);
		}
	};
}

// (29:2) {#if $store.discarded !== undefined}
function create_if_block_2(ctx) {
	let current;

	const discardinfo = new DiscardInfo({
			props: {
				tile: /*$store*/ ctx[0].tiles[/*$store*/ ctx[0].discarded]
			}
		});

	return {
		c() {
			create_component(discardinfo.$$.fragment);
		},
		m(target, anchor) {
			mount_component(discardinfo, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const discardinfo_changes = {};
			if (dirty & /*$store*/ 1) discardinfo_changes.tile = /*$store*/ ctx[0].tiles[/*$store*/ ctx[0].discarded];
			discardinfo.$set(discardinfo_changes);
		},
		i(local) {
			if (current) return;
			transition_in(discardinfo.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(discardinfo.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(discardinfo, detaching);
		}
	};
}

// (32:2) {#if $store.wind}
function create_if_block_1(ctx) {
	let current;
	const currentwind = new CurrentWind({ props: { wind: /*$store*/ ctx[0].wind } });

	return {
		c() {
			create_component(currentwind.$$.fragment);
		},
		m(target, anchor) {
			mount_component(currentwind, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const currentwind_changes = {};
			if (dirty & /*$store*/ 1) currentwind_changes.wind = /*$store*/ ctx[0].wind;
			currentwind.$set(currentwind_changes);
		},
		i(local) {
			if (current) return;
			transition_in(currentwind.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(currentwind.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(currentwind, detaching);
		}
	};
}

function create_fragment(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$store*/ ctx[0].started) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $store;
	const { socket, store } = context();
	component_subscribe($$self, store, value => $$invalidate(0, $store = value));

	const ORDER = {
		Ton: ["Shaa", "Nan", "Pei", "Ton"],
		Shaa: ["Ton", "Pei", "Nan", "Shaa"],
		Nan: ["Pei", "Shaa", "Ton", "Nan"],
		Pei: ["Nan", "Ton", "Shaa", "Pei"]
	};

	return [$store, socket, store, ORDER];
}

class Status extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Status;