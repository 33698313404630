/* app/Tiles.svelte generated by Svelte v3.20.1 */
import {
	SvelteComponent,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	empty,
	group_outros,
	init,
	insert,
	is_function,
	mount_component,
	safe_not_equal,
	set_store_value,
	transition_in,
	transition_out
} from "svelte/internal";

import { get } from "svelte/store";
import Tile from "./Tile.svelte";
import Schema from "../lib/schema.js";
import context from "../game/context.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[22] = list[i];
	child_ctx[24] = i;
	return child_ctx;
}

// (281:0) {#if $store}
function create_if_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value = /*$store*/ ctx[2].tiles;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*tableAngle, $store, handlers, $selection*/ 15) {
				each_value = /*$store*/ ctx[2].tiles;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(each_1_anchor);
		}
	};
}

// (282:2) {#each $store.tiles as tile, index}
function create_each_block(ctx) {
	let current;

	const tile = new Tile({
			props: {
				tableAngle: /*tableAngle*/ ctx[0],
				tile: /*tile*/ ctx[22],
				index: /*index*/ ctx[24],
				clickable: !!/*handlers*/ ctx[1][/*index*/ ctx[24]],
				selected: /*$selection*/ ctx[3].has(/*index*/ ctx[24])
			}
		});

	tile.$on("click", function () {
		if (is_function(/*handlers*/ ctx[1][/*index*/ ctx[24]])) /*handlers*/ ctx[1][/*index*/ ctx[24]].apply(this, arguments);
	});

	return {
		c() {
			create_component(tile.$$.fragment);
		},
		m(target, anchor) {
			mount_component(tile, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const tile_changes = {};
			if (dirty & /*tableAngle*/ 1) tile_changes.tableAngle = /*tableAngle*/ ctx[0];
			if (dirty & /*$store*/ 4) tile_changes.tile = /*tile*/ ctx[22];
			if (dirty & /*handlers*/ 2) tile_changes.clickable = !!/*handlers*/ ctx[1][/*index*/ ctx[24]];
			if (dirty & /*$selection*/ 8) tile_changes.selected = /*$selection*/ ctx[3].has(/*index*/ ctx[24]);
			tile.$set(tile_changes);
		},
		i(local) {
			if (current) return;
			transition_in(tile.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(tile.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(tile, detaching);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*$store*/ ctx[2] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*$store*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);
					transition_in(if_block, 1);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $store;
	let $selection;
	let $selectionSets;
	let $hasAction;
	let { tableAngle } = $$props;
	const { selection, selectionSets, socket, store, timer, hasAction } = context();
	component_subscribe($$self, selection, value => $$invalidate(3, $selection = value));
	component_subscribe($$self, selectionSets, value => $$invalidate(18, $selectionSets = value));
	component_subscribe($$self, store, value => $$invalidate(2, $store = value));
	component_subscribe($$self, hasAction, value => $$invalidate(19, $hasAction = value));
	let discarded;
	let myWind;
	let myTurn;
	let myDiscard;
	let toDraw = -1;
	let exactMatches = [];
	
	let canMatchSelection;
	let canWin = false;
	let canChow = [];
	let selecting = false;
	let handlers;

	$$self.$set = $$props => {
		if ("tableAngle" in $$props) $$invalidate(0, tableAngle = $$props.tableAngle);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$store*/ 4) {
			$: $$invalidate(8, discarded = $store && $store.tiles[$store.discarded]);
		}

		if ($$self.$$.dirty & /*$store*/ 4) {
			$: $$invalidate(9, myWind = $store && $store.playerWind(socket.name));
		}

		if ($$self.$$.dirty & /*$store, myWind*/ 516) {
			$: $$invalidate(10, myTurn = $store && $store.turn === myWind);
		}

		if ($$self.$$.dirty & /*$store, myWind*/ 516) {
			$: $$invalidate(11, myDiscard = $store && $store.previousTurn === myWind);
		}

		if ($$self.$$.dirty & /*$store*/ 4) {
			$: {
				const store = $store;

				if (store) {
					if (store.roll !== undefined) {
						const [wall, stackIndex] = store.nextDraw();
						const stack = store.walls[wall][stackIndex];
						$$invalidate(12, toDraw = stack[stack.length - 1]);
					} else {
						$$invalidate(12, toDraw = -1);
					}
				}
			}
		}

		if ($$self.$$.dirty & /*$store, discarded, myWind*/ 772) {
			$: {
				const store = $store;

				if (discarded) {
					$$invalidate(13, exactMatches = store[myWind].up.filter(tile => store.tiles[tile].suit === discarded.suit && store.tiles[tile].value === discarded.value));
				} else {
					$$invalidate(13, exactMatches = []);
				}
			}
		}

		if ($$self.$$.dirty & /*$selection*/ 8) {
			$: $$invalidate(14, canMatchSelection = selectionSet => [...$selection].every(tile => selectionSet.tiles.includes(tile)));
		}

		if ($$self.$$.dirty & /*$store, discarded, myWind*/ 772) {
			$: {
				const store = $store;

				if (discarded) {
					const player = { ...store[myWind] };
					player.up = [...player.up, store.discarded];
					$$invalidate(15, canWin = Schema.winningHand(store, player, store.discarded));
				} else {
					$$invalidate(15, canWin = false);
				}
			}
		}

		if ($$self.$$.dirty & /*$store, discarded, myWind*/ 772) {
			$: {
				const store = $store;

				if (discarded) {
					if (typeof discarded.value === "number") {
						const ofSuit = store[myWind].up.filter(tile => store.tiles[tile].suit === discarded.suit);

						const required = [
							ofSuit.find(tile => store.tiles[tile].value === discarded.value - 2),
							ofSuit.find(tile => store.tiles[tile].value === discarded.value - 1),
							ofSuit.find(tile => store.tiles[tile].value === discarded.value + 1),
							ofSuit.find(tile => store.tiles[tile].value === discarded.value + 2)
						];

						$$invalidate(16, canChow = [
							required.slice(0, 2).every(x => typeof x === "number")
							? [required[0], required[1]]
							: null,
							required.slice(1, 3).every(x => typeof x === "number")
							? [required[1], required[2]]
							: null,
							required.slice(2, 4).every(x => typeof x === "number")
							? [required[2], required[3]]
							: null
						].filter(x => x));
					}
				} else {
					$$invalidate(16, canChow = []);
				}
			}
		}

		if ($$self.$$.dirty & /*$store, myWind, exactMatches, myTurn, canChow, canWin*/ 108036) {
			$: {
				const list = [];
				const store = $store;

				if (myWind) {
					const pongs = [];

					if (exactMatches.length === 2) {
						pongs.push(exactMatches);
					} else if (exactMatches.length === 3) {
						pongs.push(...[
							[exactMatches[0], exactMatches[1]],
							[exactMatches[1], exactMatches[2]],
							[exactMatches[2], exactMatches[0]]
						]);

						list.push({
							tiles: exactMatches,
							label: "Kong",
							async handler() {
								try {
									await socket.send("kong", { mode: "exposed" });
									selection.set(new Set());
									$$invalidate(17, selecting = false);
								} catch(error) {
									console.error(error);
								}
							}
						});
					}

					for (const tiles of pongs) {
						list.push({
							tiles,
							label: "Pong",
							async handler() {
								try {
									await socket.send("pong");
									selection.set(new Set());
									$$invalidate(17, selecting = false);
								} catch(error) {
									console.error(error);
								}
							}
						});

						const player = { ...store[myWind] };
						player.up = player.up.filter(tile => !tiles.includes(tile));
						player.down = [...player.down, [...tiles, store.discarded]];

						if (Schema.winningHand(store, player)) {
							list.push({
								tiles,
								label: "Win",
								async handler() {
									try {
										await socket.send("win", { method: "Pong" });
										selection.set(new Set());
										$$invalidate(17, selecting = false);
									} catch(error) {
										console.error(error);
									}
								}
							});
						}
					}

					if (myTurn) {
						list.push(...canChow.map(tiles => ({
							tiles,
							label: "Chow",
							async handler() {
								try {
									await socket.send("chow", { tiles });
									selection.set(new Set());
									$$invalidate(17, selecting = false);
								} catch(error) {
									console.error(error);
								}
							}
						})));
					}

					if (canWin) {
						list.push({
							tiles: [exactMatches[0]],
							label: "Win",
							async handler() {
								try {
									await socket.send("win", { method: "Eyes" });
									selection.set(new Set());
									$$invalidate(17, selecting = false);
								} catch(error) {
									console.error(error);
								}
							}
						});
					}

					const willWin = tiles => {
						const player = { ...store[myWind] };
						player.up = player.up.filter(tile => !tiles.includes(tile));
						player.down = [...player.down, [...tiles, store.discarded]];
						return Schema.winningHand(store, player);
					};

					list.push(...canChow.filter(willWin).map(tiles => ({
						tiles,
						label: "Win",
						async handler() {
							try {
								await socket.send("win", { method: "Chow", tiles });
								selection.set(new Set());
								$$invalidate(17, selecting = false);
							} catch(error) {
								console.error(error);
							}
						}
					})));
				}

				selectionSets.set(list);
			}
		}

		if ($$self.$$.dirty & /*$store, myTurn, toDraw, $selectionSets, canMatchSelection, selecting, myDiscard*/ 416772) {
			$: {
				const store = $store;

				if (store) {
					if (store.completed) {
						$$invalidate(1, handlers = []);
					} else {
						$$invalidate(1, handlers = store.tiles.map((tile, index) => {
							if (myTurn) {
								if (typeof store.drawn === "number") {
									if (store[store.turn].up.includes(index)) {
										return async () => {
											try {
												await socket.send("discard", { tile: index });
											} catch(error) {
												console.error(error);
											}
										};
									}
								} else if (index === toDraw) {
									return async () => {
										try {
											await socket.send("draw", {});
										} catch(error) {
											console.error(error);
										}
									};
								}
							}

							if ([].concat(...$selectionSets.filter(canMatchSelection).map(set => set.tiles)).includes(index) && selecting) {
								return () => {
									const selected = get(selection);

									if (selected.has(index)) {
										selected.delete(index);
									} else {
										selected.add(index);
									}

									selection.set(selected);
								};
							}

							if (index === store.discarded && !myDiscard) {
								if ($selectionSets.length === 1) {
									return async () => {
										await $selectionSets[0].handler();
									};
								} else if ($selectionSets.length > 1) {
									return async () => {
										try {
											if (selecting) {
												selection.set(new Set());
												$$invalidate(17, selecting = !selecting);
												await socket.send("ignore");
											} else {
												// Clear the timeout so we don't get penalized for slow clicking, but let's leave the timer value so it
												// doesn't get reset
												const { handle } = get(timer) || {};

												if (handle) {
													window.clearTimeout(handle);
												}

												$$invalidate(17, selecting = !selecting);
											}
										} catch(error) {
											console.error(error);
										}
									};
								}
							}
						}));
					}
				}
			}
		}

		if ($$self.$$.dirty & /*myTurn, $selectionSets*/ 263168) {
			$: set_store_value(hasAction, $hasAction = !myTurn && $selectionSets.length);
		}
	};

	return [
		tableAngle,
		handlers,
		$store,
		$selection,
		selection,
		selectionSets,
		store,
		hasAction
	];
}

class Tiles extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { tableAngle: 0 });
	}
}

export default Tiles;